import React, { useEffect, useState } from 'react';
import './FontFamilySelector.css';
import { fontList } from './FontList';

export default function FontFamilySelector(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedFontFamily, setSelectedFontFamily] = useState(props.value ? props.value : fontList[0].fontFamily)

    const renderFontOptions = () => {
        let items = [];

        fontList.forEach((font, index) => {
            items.push(
                <li 
                    key={`font-${index}`} 
                    onClick={() => {
                        setSelectedFontFamily(font.fontFamily);
                        setShowDropdown(false);
                        props.onChange(font.fontFamily);
                    }}
                    style={{ fontFamily: font.fontFamily }}
                >
                    {font.fontFamily}
                </li>
            )
        })

        return items;
    }

    return  (
        <div className="font-selector">
            <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                    <label id="PolarisTextField1Label" htmlFor="PolarisTextField1" className="Polaris-Label__Text">
                        {props.label}
                    </label>
                </div>
            </div>

            <div className="Polaris-Select">
                <select onClick={() => setShowDropdown(!showDropdown)} id="PolarisSelect1" className="Polaris-Select__Input" aria-invalid="false">
                </select>
                <div className="Polaris-Select__Content" aria-hidden="true">
                    <span className="Polaris-Select__SelectedOption" style={{ fontFamily: selectedFontFamily }}>{selectedFontFamily}</span>
                    <span className="Polaris-Select__Icon">
                        <span className="Polaris-Icon">
                            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z" fillRule="evenodd"></path>
                            </svg>
                        </span>
                    </span>
                </div>
                <div className="Polaris-Select__Backdrop"></div>
            </div>

            {showDropdown &&
                <ul className="Polaris-Card font-selector-dropdown">
                    {renderFontOptions()}
                </ul>
            }
        </div>
    )
}