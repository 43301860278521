import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyCPYg153VIDCgfWFbHkG77UVtq5DMNCHBY",
    authDomain: "torden-announcement-bar.firebaseapp.com",
    databaseURL: "https://torden-announcement-bar.firebaseio.com",
    projectId: "torden-announcement-bar",
    storageBucket: "torden-announcement-bar.appspot.com",
    messagingSenderId: "360220310654",
    appId: "1:360220310654:web:f01835e06d8593dd146c2f"
};

export const firebaseService = {
    authenticate,
    getShopData,
    saveShopData,
    signOut
};

function authenticate(token) {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }

    const auth = firebase.auth();

    // Try to sign in into Firebase with the access token
    return auth.signInWithCustomToken(token)
        .then(() => {
            // If the user is logged out for any reason, reload the
            // page to restart the app in a logged out state
            auth.onAuthStateChanged(function(user) {
                if (!user) {
                    window.location.reload();
                }
            });

        })
}

function signOut() {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }

    const auth = firebase.auth();
    auth.signOut();
}

function getShopData(shopOrigin) {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }

    const db = firebase.firestore();
    let docRef = db.collection("shops").doc(shopOrigin);

    return docRef.get().then(doc => {
        if (doc.exists) {
            return doc.data();
        } else {
            return null;
        }
    }).catch(() => {
        return null;
    });
}

function saveShopData(shopOrigin, data) {
    if (!firebase.apps.length) {
        firebase.initializeApp(config);
    }

    const db = firebase.firestore();
    let docRef = db.collection("shops").doc(shopOrigin);

    return docRef.get().then(doc => {
        data['updated_at'] = firebase.firestore.Timestamp.now();

        if (doc.exists) {
            return docRef.update(data);
        } else {
            data['created_at'] = firebase.firestore.Timestamp.now();
            return db.collection('shops').doc(shopOrigin).set(data);
        }
    })
}