import React, { useState, useEffect, useRef } from 'react'
import '../_assets/css/announcementBarPreview.css'

export default function AnnouncementBarPreview(props) {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })
      
    let { 
        backgroundColor, 
        buttonBackgroundColor,
        buttonText,
        buttonTextColor,
        callToActionType,
        customCss,
        fontFamily,
        fontSize,
        message,
        messageFontColor,
        opacity,
        showCloseButton
    } = props.shopData;

    // Get default texts if they are empty
    buttonText = buttonText || 'Click here';
    message = message || 'This is your message';

    return  (
        <div>
            <style>{customCss}</style>
            <div className="torden-sab-preview-section" style={{ height: `${height}px`}}>
                <div className="torden-sab-preview-container" ref={ref}>
                    <p className="torden-sab-preview-text">PREVIEW</p>

                    {/* START div rendered on store's frontend */}
                    <div className="torden-sab-container">
                        <div className="torden-sab-bar-container">
                            {callToActionType === 'full' &&
                                <a className="torden-sab-full-link"></a>
                            }

                            <div className="torden-sab-background" style={{ backgroundColor, opacity }} />

                            <span className="torden-sab-text" style={{color: messageFontColor, fontFamily, fontSize: `${fontSize}px`}}>
                                {message}
                            </span>

                            {callToActionType === 'button' &&
                                <a className="torden-sab-button" style={{ backgroundColor: buttonBackgroundColor, color: buttonTextColor }}>{buttonText}</a>
                            }

                            {showCloseButton === 'yes' &&
                                <a className="torden-sab-close-button" style={{ color: messageFontColor }}>X</a>
                            }
                        </div>
                    </div>
                    {/* END div rendered on store's frontend */}

                </div>
            </div>
        </div>
    )
}