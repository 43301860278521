import React from 'react';
import { Card, FormLayout, Layout, RangeSlider, Select, TextField } from "@shopify/polaris";
import CustomColorPicker from './CustomColorPicker';
import FontFamilySelector from './FontFamilySelector/FontFamilySelector';

export default function BasicSettingsSection(props) {
    const getFontSizeOptions = (min, max) => {
        let options = [];

        for(let i = min; i <= max; i++) {
            options.push(
                {label: `${i}`, value: `${i}`}
            )
        }

        return options;
    }

    return  (
        <Layout.AnnotatedSection
            title="Basic settings"
            description="Set the message displayed on the bar, its font size and color, and other basic configuration."
        >
            <Card sectioned>
                <FormLayout>
                    <TextField 
                        error={props.errors.message}
                        label="Message"
                        onChange={value => props.updateInput('message', value)} 
                        placeholder="Example: Summer sale starts today!" 
                        value={props.shopData.message}
                    />

                    <FontFamilySelector 
                        label="Font style"
                        onChange={value => props.updateInput('fontFamily', value)} 
                        value={props.shopData.fontFamily}
                    />

                    <Select
                        label="Font size"
                        options={getFontSizeOptions(12, 30)}
                        onChange={value => props.updateInput('fontSize', value)} 
                        value={props.shopData.fontSize}
                    />

                    <CustomColorPicker
                        color={props.shopData.messageFontColor}
                        label={'Font color'}
                        onChange={value => props.updateInput('messageFontColor', value)} 
                    />

                    <CustomColorPicker
                        color={props.shopData.backgroundColor}
                        label={'Background color'}
                        onChange={value => props.updateInput('backgroundColor', value)} 
                    />

                    <RangeSlider 
                        error={props.errors.opacity}
                        label="Background opacity"
                        max={1}
                        min={0.1}
                        onChange={value => props.updateInput('opacity', value)} 
                        output
                        step={0.1}
                        value={props.shopData.opacity}
                    />
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
}