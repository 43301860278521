import React from 'react';
import {Context} from '@shopify/app-bridge-react';
import {I18nContext, I18nManager} from '@shopify/react-i18n';
import {Frame, Loading} from '@shopify/polaris';
import _ from 'lodash';

export default class LocaleProvider extends React.Component {
  static contextType = Context;

  state = {
    loading: true,
    locale: 'en-US'
  }

  render() {
    const app = this.context;

    if(this.state.loading) {
        console.log('loading on LocaleProvider', this.state)
        app.getState()
        .then(state => {
            console.log('app state on LocaleProvider', state);
            const locale = _.get(state, 'staffMember.locale', 'en-US');
            this.setState({ loading: false, locale })
        })
        .catch(() => {
            console.log('catch on LocaleProvider');
            this.setState({ loading: false });
        })
        .finally(() => {
            console.log('finally on LocaleProvider');
        })

        return (
            <Frame>
                <Loading />
            </Frame>
        )
    }

    const i18nManager = new I18nManager({ locale: this.state.locale });

    return (
        <I18nContext.Provider value={i18nManager}>
            {this.props.children}
        </I18nContext.Provider>
    );
  }
}