export const fontList = [
	{
		"fontFamily":"Bangers",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Carter One",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Chewy",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Courier",
		"isGoogleFont":false
	},
	{
		"fontFamily":"Crafty Girls",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Dancing Script",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Great Vibes",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Helvetica",
		"isGoogleFont":false,
	},
	{
		"fontFamily":"Josefin Sans",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Julius Sans One",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Lato",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Lobster",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Monospace",
		"isGoogleFont":false
	},
	{
		"fontFamily":"Montserrat",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Muli",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Mystery Quest",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Open Sans",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Oswald",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Playfair Display",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Poiret One",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Quicksand",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Raleway",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Righteous",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Roboto",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Serif",
		"isGoogleFont":false
	},
	{
		"fontFamily":"Times New Roman",
		"isGoogleFont":false
	},
	{
		"fontFamily":"Unica One",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Varela Round",
		"isGoogleFont":true
	},
	{
		"fontFamily":"Verdana",
		"isGoogleFont":false
	}
]

export const getGoogleFontsFamilyNames = () => {
    const googleFonts = fontList.filter(font => font.isGoogleFont)

    let fontsArray = [];
    googleFonts.forEach(font => {
        fontsArray.push(font.fontFamily);
    });

    return fontsArray;
}

export const getGoogleFontsCssUrl = () => {
	const familyNames = getGoogleFontsFamilyNames();

	let baseUrl = 'https://fonts.googleapis.com/css?family=';
	let families = "";

	familyNames.forEach(familyName => {
		families += familyName + '|';
	})

	return baseUrl + encodeURIComponent(families);
}