import React, { Component } from 'react';
import { Frame, Layout, Loading, Page, PageActions, Toast } from "@shopify/polaris";
import { firebaseService } from "./_services/firebaseService";
import errorCodes from "./_errors/errorCodes";
import _ from 'lodash';
import AnnouncementBarPreview from './_components/AnnouncementBarPreview';
import BasicSettingsSection from './_components/BasicSettingsSection';
import StatusSection from './_components/StatusSection';
import CallToActionSection from './_components/CallToActionSection';
import DisplaySection from './_components/DisplaySection';
import AdvancedSection from './_components/AdvancedSection';
import ThemeSection from './_components/ThemeSection';
import { withI18n } from '@shopify/react-i18n';
import en from './_translations/en.json'
import pt from './_translations/pt.json'

const REQUIRED_MESSAGE = "This field is required";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopData: {
        backgroundColor: '#000',
        barPageTarget: 'all',
        barPosition: 'top',
        barPositionFixed: 'yes',
        buttonBackgroundColor: '#D8AB2E',
        buttonText: '',
        buttonTextColor: '#000',
        callToActionType: 'button',
        callToActionUrl: '',
        callToActionUrlTarget: '_blank',
        customCss: '',
        customPageTargetUrl: '',
        fontFamily: 'Verdana',
        fontSize: '16',
        message: '',
        messageFontColor: '#F2CA80',
        opacity: 1,
        status: false,
        showCloseButton: 'yes'
      },
      errors: {
        message: false,
        buttonText: false,
        callToActionUrl: false,
        customPageTargetUrl: false
      },
      isFirstLoading: true,
      isSaving: false,
      toast: {
        content: '',
        error: false,
        show: false
      }
    };
  }

  toogleToastVisibility = (content = '', error = false) => {
    this.setState({
      toast: {
        content,
        error,
        show: !this.state.toast.show
      }
    })
  }

  toggleStatus = () => {
    this.updateInput('status', !this.state.shopData.status);
  }

  updateInput = (key, value) => {
    this.setState({
      shopData: {
        ...this.state.shopData,
        [key]: value
      }
    });
  }

  onThemeSelect = values => {
    this.setState({
      shopData: {
        ...this.state.shopData,
        ...values
      }
    })
  }

  componentDidMount = () => {
    // If we don't have a shopOrigin, redirect to the error page
    if(_.isEmpty(this.props.shopOrigin)) {
      window.location.href = `/?error=${errorCodes.MISSING_SHOP_PARAMETER_ON_APP}`;
      return;
    }

    firebaseService.getShopData(this.props.shopOrigin).then(shopData => {
      if(_.isEmpty(shopData)) {
        window.location.href = `/?error=${errorCodes.ERROR_GETTING_SHOP_DATA}`;
        return;
      }

      this.setState({
        shopData: {
          ...this.state.shopData,
          ...shopData
        },
        isFirstLoading: false
      })
    })
  }

  save = () => {
    const { shopData } = this.state;

    this.setState({ errors: {
      message: _.isEmpty(shopData.message) ? REQUIRED_MESSAGE : false,
      buttonText: (shopData.callToActionType === 'button' && _.isEmpty(shopData.buttonText)) ? REQUIRED_MESSAGE : false,
      callToActionUrl: ((shopData.callToActionType === 'button' || shopData.callToActionType === 'full') && _.isEmpty(shopData.callToActionUrl)) ? REQUIRED_MESSAGE : false,
      customPageTargetUrl: (shopData.barPageTarget === 'custom' && _.isEmpty(shopData.customPageTargetUrl)) ? REQUIRED_MESSAGE : false
    }}, () => {
        // Check any property of this.state.errors is true (if so, there is an error)
        if(!_.some(_.values(this.state.errors), Boolean)) {          
          this.setState({isSaving : true});

          firebaseService.saveShopData(this.props.shopOrigin, shopData)
            .then(() => {
              this.setState({isSaving : false});
              this.toogleToastVisibility('Configuration updated');
            })
            .catch(() => {
              this.setState({isSaving : false});
              this.toogleToastVisibility('Error on saving', true);
            });
        } else {
          if(!!this.state.errors.message) {
            return window.location.href = "#sab_theme_section";
          }

          if(!!this.state.errors.buttonText || !!this.state.errors.callToActionUrl) {
            return window.location.href = "#sab_cta_section";
          }

          if(!!this.state.errors.customPageTargetUrl) {
            return window.location.href = "#sab_display_section";
          }
        }
    })
  }

  render() {
    if(this.state.isFirstLoading) {
      return (
        <Frame>
          <Loading />
        </Frame>
      )
    }

    const { errors, shopData, toast } = this.state;

    return (
      <Frame>
        <AnnouncementBarPreview 
          shopData={shopData}
        />

        <Page>
          {toast.show && 
            <Toast content={toast.content} error={toast.error} duration={5000} onDismiss={this.toogleToastVisibility} />
          }

          <Layout>
            <StatusSection 
              errors={errors}
              shopData={shopData}
              toggleStatus={this.toggleStatus}
            />

            <ThemeSection onThemeSelect={this.onThemeSelect} />

            <BasicSettingsSection 
              errors={errors}
              shopData={shopData}
              updateInput={this.updateInput}
            />

            <CallToActionSection 
              errors={errors}
              shopData={shopData}
              updateInput={this.updateInput}
            />

            <DisplaySection 
              errors={errors}
              shopData={shopData}
              updateInput={this.updateInput}
            />

            <AdvancedSection 
              errors={errors}
              shopData={shopData}
              updateInput={this.updateInput}
            />

            <Layout.Section>
              <PageActions
                primaryAction={{
                  loading: this.state.isSaving,
                  content: 'Save',
                  onAction: this.save
                }}
              />
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    );
  }
}

export default withI18n({
  id: 'App',
  fallback: en,
  translations(locale) {
    console.log('locale on App.js', locale);
    if (locale === 'en') {
      return en;
    } else if (locale === 'pt') {
      return pt;
    }
  }
})(App);