import React from 'react';
import { Card, FormLayout, Layout } from "@shopify/polaris";
import ThemeOption from './ThemeOption';

export default function ThemeSection(props) {
    return  (
        <Layout.AnnotatedSection
            title="Themes"
            description="Select one of the themes to quick configure your bar layout."
        >
            <span id="sab_status_section"></span>
            <Card sectioned>
                <FormLayout>
                    <ThemeOption 
                        backgroundColor={'#000'}
                        buttonBackgroundColor={'#D8AB2E'}
                        buttonTextColor={'#000'}
                        label={'Dark Gold'}
                        messageFontColor={'#F2CA80'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />

                    <ThemeOption 
                        backgroundColor={'#4c4c4c'}
                        buttonBackgroundColor={'#D8AB2E'}
                        buttonTextColor={'#000'}
                        label={'Elegant'}
                        messageFontColor={'#CCCCCC'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />

                    <ThemeOption 
                        backgroundColor={'#f2f2f2'}
                        buttonBackgroundColor={'#4c4c4c'}
                        buttonTextColor={'#fff'}
                        label={'Light Grey'}
                        messageFontColor={'#354B5E'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />

                    <ThemeOption 
                        backgroundColor={'#fff'}
                        buttonBackgroundColor={'#F23E43'}
                        buttonTextColor={'#fff'}
                        label={'Clear'}
                        messageFontColor={'#F23E43'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />


                    <ThemeOption 
                        backgroundColor={'#fff0a5'}
                        buttonBackgroundColor={'#FFB03B'}
                        buttonTextColor={'#8E2800'}
                        label={'Blond'}
                        messageFontColor={'#B64926'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />


                    <ThemeOption 
                        backgroundColor={'#6fbeff'}
                        buttonBackgroundColor={'#FFC229'}
                        buttonTextColor={'#000'}
                        label={'Calm Mood'}
                        messageFontColor={'#fff'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />
                   

                   <ThemeOption 
                        backgroundColor={'#8acc4c'}
                        buttonBackgroundColor={'#00541b'}
                        buttonTextColor={'#fff'}
                        label={'Nature'}
                        messageFontColor={'#184800'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />


                    <ThemeOption 
                        backgroundColor={'#f5ddde'}
                        buttonBackgroundColor={'#c97074'}
                        buttonTextColor={'#fff'}
                        label={'Pastel Pink'}
                        messageFontColor={'#c97074'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />

                   <ThemeOption 
                        backgroundColor={'#eb4034'}
                        buttonBackgroundColor={'#fff'}
                        buttonTextColor={'#eb4034'}
                        label={'Alert'}
                        messageFontColor={'#fff'}
                        opacity={1}
                        onThemeSelect={props.onThemeSelect}
                    />

                    <span id="sab_theme_section"></span>
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
}