import React from 'react';
import { Card, FormLayout, Layout, TextField } from "@shopify/polaris";

export default function AdvancedSection(props) {
    return  (
        <Layout.AnnotatedSection
            title="Custom CSS"
            description="Do you want to customize your bar even further? You can paste your own CSS in the next text area."
        >
            <Card sectioned>
                <FormLayout>
                    <TextField 
                        error={props.errors.customCss}
                        onChange={value => props.updateInput('customCss', value)} 
                        value={props.shopData.customCss}
                        multiline={5}
                    />
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
}