import React from "react";
import {
  Button,
  Popover,
  ColorPicker,
  Stack,
  TextField,
  hsbToHex
} from "@shopify/polaris";
import convert from 'color-convert';
import "@shopify/polaris/styles.css";

export default class CustomColorPicker extends React.Component {

  constructor(props) {
    super(props);

    if(props.color) {
      this.state = {
        color: this.hexToHsb(props.color),
        hexColor: props.color,
        popoverActive: false,
        internalChange: false
      }
    } else {
      this.state = {
        color: {
          hue: 1,
          brightness: 0,
          saturation: 0,
        },
        hexColor: '#000000',
        popoverActive: false,
        internalChange: false
      };
    }
  }
  
  hexToHsb = (value) => {
    const color = convert.hex.hsv(value); 
    return{
      hue: color[0],
      saturation: color[1],
      brightness: color[2]
    }
  }

  componentDidUpdate = (prevProps) => {
    if((prevProps.color !== this.props.color) && !this.state.internalChange) {
      this.setState({
        color: this.hexToHsb(this.props.color),
        hexColor: this.props.color,
        popoverActive: false
      })
    }
  } 

  handleColorChange = (color) => {
    const hexColor = hsbToHex(color);
    this.setState({ 
      color,
      hexColor,
      internalChange: true
    });
    this.props.onChange(hexColor);
  }

  handleHexChange = (value) => {
      this.setState({ 
        color: this.hexToHsb(value),
        hexColor: value,
        internalChange: true
      });
      this.props.onChange(value);
  }

  handlePopoverClose = () => {
    this.setState({ popoverActive: false, internalChange: false });
  }

  handlePopoverOpen = () => {
    this.setState({ popoverActive: true });
  }

  render() {
    const { color, hexColor, popoverActive } = this.state;

    const activator = (
      <Button onClick={this.handlePopoverOpen}>
        <Stack alignment="center" spacing="tight">
          <div
            style={{
              height: "2rem",
              width: "2rem",
              borderRadius: "0.3rem",
              background: hexColor.includes('#') ? hexColor : `#${hexColor}`
            }}
          />
        </Stack>
      </Button>
    );

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ marginRight: '20px'}}>{this.props.label}</p>
      <Popover
        active={popoverActive}
        activator={activator}
        onClose={this.handlePopoverClose}
      >
        <Popover.Section>
          <ColorPicker
            onChange={this.handleColorChange}
            color={color}
          />
        </Popover.Section>
        <Popover.Section>
          <TextField value={hexColor} onChange={this.handleHexChange} />
        </Popover.Section>
      </Popover>
      </div>
    );
  }
}