import React from 'react';
import { Card, FormLayout, Layout, TextField, Select } from "@shopify/polaris";
import CustomColorPicker from './CustomColorPicker';

export default function CallToActionSection(props) {
    return  (
        <Layout.AnnotatedSection
            title="Call to action"
            description="Define if and how your customers will be able to take an action from the bar."
        >
            <Card sectioned>
                <FormLayout>
                    <span id="sab_cta_section"></span>

                    <Select
                        label="Call to action type"
                        options={[
                            {label: 'No action', value: 'no_action'},
                            {label: 'Make the entire bar clickable', value: 'full'},
                            {label: 'Include a button on the bar', value: 'button'},
                        ]}
                        onChange={value => props.updateInput('callToActionType', value)} 
                        value={props.shopData.callToActionType}
                    />

                    
                    {props.shopData.callToActionType === 'button' &&
                        <TextField 
                            error={props.errors.buttonText}
                            label="Button text" 
                            onChange={value => props.updateInput('buttonText', value)} 
                            placeholder="Example: Click here" 
                            value={props.shopData.buttonText}
                        />
                    }

                    {(props.shopData.callToActionType === 'full' || props.shopData.callToActionType === 'button') &&
                        <TextField 
                            error={props.errors.callToActionUrl}
                            label={props.shopData.callToActionType === 'full' ? "Click target URL" : "Button target URL"}
                            onChange={value => props.updateInput('callToActionUrl', value)} 
                            placeholder="Example: https://mystore.com/collections/sale" 
                            value={props.shopData.callToActionUrl}
                        />
                    }

                    {(props.shopData.callToActionType === 'full' || props.shopData.callToActionType === 'button') &&
                        <Select
                            label="Where do you want to open the URL?"
                            options={[
                                {label: 'Open the url in a new window', value: '_blank'},
                                {label: 'Open the url in the same window', value: '_self'},
                            ]}
                            onChange={value => props.updateInput('callToActionUrlTarget', value)} 
                            value={props.shopData.callToActionUrlTarget}
                        />
                    }

                    {props.shopData.callToActionType === 'button' &&
                        <CustomColorPicker
                            color={props.shopData.buttonTextColor}
                            label={'Button text color'}
                            onChange={value => props.updateInput('buttonTextColor', value)} 
                        />
                    }

                    {props.shopData.callToActionType === 'button' &&
                        <CustomColorPicker
                            color={props.shopData.buttonBackgroundColor}
                            label={'Button background color'}
                            onChange={value => props.updateInput('buttonBackgroundColor', value)} 
                        />
                    }
                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
}