import React from 'react';
import { Card, FormLayout, Layout, Select, TextField } from "@shopify/polaris";

export default function DisplaySection(props) {
    return  (
        <Layout.AnnotatedSection
            title="Display"
            description="Customize where and in which pages your bar will be displayed."
        >
            <Card sectioned>
                <FormLayout>
                    <span id="sab_display_section"></span>

                    <Select
                        label="Bar position"
                        options={[
                            {label: 'Top', value: 'top'},
                            {label: 'Bottom', value: 'bottom'}
                        ]}
                        onChange={value => props.updateInput('barPosition', value)} 
                        value={props.shopData.barPosition}
                    />

                    <Select
                        label="Keep bar visible on page scroll?"
                        options={[
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'}
                        ]}
                        onChange={value => props.updateInput('barPositionFixed', value)} 
                        value={props.shopData.barPositionFixed}
                    />

                    <Select
                        label="In which pages do you want to show the bar?"
                        options={[
                            {label: 'Show on every page', value: 'all'},
                            {label: 'Show only on the home page', value: 'home'},
                            {label: 'Show only on collection pages', value: 'collection'},
                            {label: 'Show only on product pages', value: 'product'},
                            {label: 'Show only on a custom page', value: 'custom'}
                        ]}
                        onChange={value => props.updateInput('barPageTarget', value)} 
                        value={props.shopData.barPageTarget}
                    />
                    
                    {props.shopData.barPageTarget === 'custom' &&
                        <TextField 
                            error={props.errors.customPageTargetUrl}
                            label="Custom page URL"
                            onChange={value => props.updateInput('customPageTargetUrl', value)} 
                            placeholder="Example: http://myshop.com/contact" 
                            value={props.shopData.customPageTargetUrl}
                        />
                    }

                    <Select
                        label="Show close button?"
                        options={[
                            {label: 'Yes', value: 'yes'},
                            {label: 'No', value: 'no'}
                        ]}
                        onChange={value => props.updateInput('showCloseButton', value)} 
                        value={props.shopData.showCloseButton}
                    />

                </FormLayout>
            </Card>
        </Layout.AnnotatedSection>
    )
}