import React from 'react';
import _ from 'lodash';

export default function ThemeOption(props) {
    const onThemeSelect = () => {
        const values = _.omit(props, 'onThemeSelect', 'label');
        props.onThemeSelect(values);
    }

    return  (
        <div onClick={onThemeSelect} className="torden-sab-container" style={{ cursor: 'pointer' }}>
            <div className="torden-sab-bar-container">
                <div className="torden-sab-background" style={{ backgroundColor: props.backgroundColor }} />
                <span className="torden-sab-text" style={{color: props.messageFontColor, fontFamily: 'Helvetica', fontSize: '16px'}}>
                    {props.label}
                </span>
            </div>
        </div>
    )
}