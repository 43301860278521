import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/styles.css';
import {AppProvider, EmptyState, Frame, Loading} from '@shopify/polaris';
import {Provider} from '@shopify/app-bridge-react';
import enTranslations from '@shopify/polaris/locales/en.json';
import jwtDecode from 'jwt-decode';
import errorCodes from "./_errors/errorCodes";
import { firebaseService } from "./_services/firebaseService";
import { getGoogleFontsCssUrl } from "./_components/FontFamilySelector/FontList";
import LocaleProvider from './LocaleProvider';
import App from './App';

class WrappedApp extends React.Component {
  state = {
    error: false,
    errorCode: null,
    loading: true,
    shopOrigin: null
  }

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);

    // Check if we have an error on the url
    let error =  urlParams.get('error');
    if(error) {
      this.setState({ error: true, errorCode: error, loading: false });
      return;
    }

    // Try to get the access token from the url or the local storage
    let accessToken = urlParams.get('at');
    if(accessToken) {
        window.localStorage.setItem('at', accessToken);
    } else {
        accessToken = window.localStorage.getItem('at');
    }

    // If there is no access token, we return an error
    if(!accessToken) {
      this.setState({ error: true, errorCode: errorCodes.MISSSING_ACCESS_TOKEN, loading: false });
      return;
    }

    // Try to decode the access token 
    let decodedToken = {};
    try {
      decodedToken = jwtDecode(accessToken);
      
    } catch (error) {
      this.setState({ error: true, errorCode: errorCodes.ERROR_DECODING_ACCESS_TOKEN, loading: false });
      return;
    }

    // Try to get the shopOrigin
    const shopOrigin = decodedToken.uid;

    // If there is no shopOrigin, we return an error
    if(!shopOrigin) {
      this.setState({ error: true, errorCode: errorCodes.MISSING_SHOP_PARAMETER_ON_INDEX, loading: false });
      return;
    }

    // Authenticate on Firebase
    firebaseService.authenticate(accessToken).then(() => {
      this.setState({ loading: false, shopOrigin })
    })
    .catch(() => {
      this.setState({ error: true, errorCode: errorCodes.MISSSING_SHOP_PARAMETER, loading: false });
    })
  }

  render() {
    if(this.state.loading) {
      return (
        <AppProvider i18n={enTranslations}>
          <Frame>
            <Loading />
          </Frame>
        </AppProvider>
      )
    }

    if(this.state.error) {
      return (
        <AppProvider i18n={enTranslations}>
          <EmptyState
            heading="There's a problem loading your Simple Announcement Bar app configuration"
            action={{content: 'Reload this page', onAction: ()=> window.location.reload() }}
            secondaryAction={{content: 'Contact us', url: 'mailto:support@tordenapps.com' }}
            image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
          >
            <p>
              There is a technical problem that prevented this page from loading. Try reloading the page or going back to your apps listing page. If the problem persists, try again in a few moments or contact us.
              {this.state.errorCode && ` (#${this.state.errorCode})`}
            </p>
          </EmptyState>
        </AppProvider>
      )
    }

    return (
      <AppProvider i18n={enTranslations}>
        <Provider
          config={{
            apiKey: '3fa1ddf988719b5904e9d7aa0d78e7ac',
            shopOrigin: this.state.shopOrigin,
            forceRedirect: true
          }}
        >
          <LocaleProvider>
            <>
              <link rel="stylesheet" type="text/css" href={getGoogleFontsCssUrl()} />
              <App shopOrigin={this.state.shopOrigin} />
            </>
          </LocaleProvider>
        </Provider>
      </AppProvider>
    );
  }
}

ReactDOM.render(<WrappedApp />, document.getElementById('root'));